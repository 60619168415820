body {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  /* react modal */
}
body.modal-on {
  overflow-x: hidden;
  overflow-y: hidden;
}
body.modal-on header {
  display: none !important;
  box-shadow: none !important;
  outline: none;
  z-index: -1;
}
body.modal-on .floating-fixed {
  display: none;
}
body.ReactModal__Body--open {
  overflow-x: hidden;
  overflow-y: hidden;
}
body .react-modal {
  outline: none;
}
body .react-modal .react-modal-content {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  padding: 0 20px;
}
body .react-modal .react-modal-content .container {
  max-height: 100vh;
  overflow: auto;
}
body .react-modal .react-modal-content .container ol,
body .react-modal .react-modal-content .container ul {
  list-style-position: inside;
}
body .react-modal .react-modal-content .container .react-modal-content-header {
  display: flex;
  margin-bottom: 10px;
}
body .react-modal .react-modal-content .container .react-modal-content-header .modal-close {
  margin-left: auto;
  cursor: pointer;
}
body .react-overlay-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9999;
}

.survey-modal-content img.logo {
  width: 110px;
  display: block;
  margin: auto;
}
.survey-modal-content h3 {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin-top: 30px;
}
.survey-modal-content .content-row {
  margin-top: 20px;
}
.survey-modal-content .content-row p {
  font-size: 14px;
  color: #ffffff;
}
.survey-modal-content .content-row .input {
  width: 100%;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 5px 10px;
  color: #354f65;
  font-size: 14px;
  outline: none;
}
.survey-modal-content .content-row .input::placeholder {
  color: #dadada;
}
.survey-modal-content .content-row textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  resize: none;
  height: 100px;
  padding: 10px;
  color: #354f65;
  outline: none;
  font-size: 14px;
}
.survey-modal-content .content-row textarea::placeholder {
  color: #dadada !important;
}
.survey-modal-content button {
  padding: 7px 20px;
  line-height: 18px;
  font-size: 18px;
  font-weight: normal;
  border: 0;
  background: #68c552;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  color: #fff;
  display: block;
  margin: 20px auto;
  outline: none;
}
.survey-modal-content button:disabled {
  color: #3e9d29;
  cursor: not-allowed;
}

input,
textarea,
input[type=text],
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  /* Safari fix */
  box-shadow: none !important;
}

select {
  background: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ctm-select > div.ctm__control {
  min-height: unset;
  border: 1px solid #dadada !important;
}
.ctm-select > div.ctm__control > div:first-child {
  padding: 3px 10px;
}
.ctm-select .ctm__indicator-separator {
  display: none;
}
.ctm-select .ctm__control.ctm__control--is-focused {
  box-shadow: none;
  border: 1px solid #327fbe !important;
}
.ctm-select .ctm__control .ctm__value-container .ctm__placeholder {
  color: #dadada !important;
  font-size: 14px;
}
.ctm-select .ctm__control .ctm__single-value {
  color: #354f65;
  font-size: 14px;
}
.ctm-select.is-error .ctm__control {
  border-color: #e2574c !important;
}
.ctm-select .ctm__option {
  color: #354f65;
}

.ctm__indicator.ctm__dropdown-indicator {
  color: #354f65;
}

.ctm__option {
  background-color: #fff !important;
  color: #000;
  transition: background-color 0.3s;
}
.ctm__option:hover, .ctm__option.ctm__option--is-selected {
  background: #327fbe !important;
  color: #fff;
}

.ctm__indicator-separator {
  display: none;
}

.btn-common {
  padding: 7px 20px;
  font-size: 18px;
  border-radius: 15px;
  text-align: center;
  border: 0;
  margin: 20px auto 0;
  display: block;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}